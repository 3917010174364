import React from "react"
import Layout from "../components/layout"
import { FormattedMessage } from "gatsby-plugin-intl"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={{ marginTop: "6rem", color: "white", height: "100%", minHeight: "50vh" }}>
      <div style={{ padding: "1rem", maxWidth: "1300px", margin: "0 auto", display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
        <h1>
          <FormattedMessage id="notfound.header" />
        </h1>
        <p>
          <FormattedMessage id="notfound.description" />
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
